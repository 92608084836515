<template>
    <div style="width:600px; padding:30px">
        <el-form ref="saveOrEditForm" :model="saveData" :rules="rules" label-width="120px">
            <el-form-item label="原密码" prop="oldPassword">
                <el-input type="password" v-model="saveData.oldPassword"
                            placeholder="请输入原密码"/>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="saveData.newPassword"
                            placeholder="请输入新密码"/>
            </el-form-item>
            <el-form-item label="新密码确认" prop="newSecPassword">
                <el-input type="password" v-model="saveData.newSecPassword"
                            placeholder="请再次输入新密码"/>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <el-input v-model="saveData.code" style="width:300px"
                            placeholder="请输入验证码"/>
                <img class="imageCode" :src="imgCodeSrc" width=80 height=20  @click="refreshImageCode" >
            </el-form-item>
        </el-form>
        <div style="width:600px; text-align:center">
            <el-button type="primary" @click="checkBox">修改密码</el-button>
        </div>
    </div>
</template>
<script>
    import {guid} from '@/utils/tools';
	import {UserApi,CommonApi} from '@/api';
    import { mapActions } from 'vuex';
    import Md5 from 'js-md5';
    export default {
        name: "Login",
        data: function () {
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                callback(new Error('请再次输入密码'));
                } else if (value !== this.saveData.newPassword) {
                callback(new Error('两次输入密码不一致!'));
                } else {
                callback();
                }
            };
            return {
                saveData:{},
                imageGuid: '',
                rules: {
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' }
                ],
                newSecPassword: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ]
                }
            }
        },
		mounted() {
			this.refreshImageCode();
		},
        computed: {
			imgCodeSrc: function () {
				return CommonApi.getImageCode(this.imageGuid);
			}
        },
        methods: {
            ...mapActions('app', ['logout']),

            refreshImageCode: function () {
				this.imageGuid = guid();
			},
            checkBox(){
                 this.$refs.saveOrEditForm.validate((valid) => {
                    if (valid) {
                        this.updatePassword()
                    } else {
                        return false;
                    }
                    });
            },
            async updatePassword(){
               let params = {}
               params.code = this.saveData.code
               params.imageCodeId = this.imageGuid
               params.newPassword = Md5(this.saveData.newPassword)
               params.oldPassword = Md5(this.saveData.oldPassword)
               let result = await  UserApi.user.updatePassword(params)
               if(result.success){
                   this.Message.success('修改成功')
                   this.logout()
               } else{
                   this.refreshImageCode();
               }
            }
        }
    }
</script>